import React, { Component } from 'react';
import { style } from './style';
import { css } from 'aphrodite/no-important';
import Tweet from '../../components/tweet';

class Column extends Component {
  constructor() {
    super();

    this.containerRef = React.createRef();

    this.tweets = [];

    this.bottomSentinelPreviousY = 0;
    this.bottomSentinelPreviousRatio = 0;
    this.cachedHeights = {};
    this.currentIndex = 0;

    this.recycling = true;
  }

  state = {
    list: [],
    isObserving: false,
    renderedList: [],
    initial: true,
  };

  componentDidMount() {
    if (this.state.initial) this.getNext();
  }

  componentDidUpdate() {
    if (
      this.state.list.length &&
      !this.state.renderedList.length &&
      this.state.initial
    ) {
      this.initList();
    }

    if (!this.state.isObserving && this.state.renderedList.length) {
      this.initIntersectionObserver();
    }
  }

  initList = () => {
    let tweetsContainer = [];
    const { columnIndex } = this.props;

    for (let i = 0; i < this.props.nodeAmount; i++) {
      const tweet = this.state.list[i];

      if (!tweet) return;

      tweetsContainer.push(
        <li
          id={`c${columnIndex}-tweet-${i}`}
          key={tweet.position + '_' + i}
          data-source={tweet.source}
        >
          {tweet && (
            <Tweet
              tweet={tweet}
              placeholderImg={this.props.placeholderImg}
              avatarPlaceholder={this.props.avatarPlaceholder}
              relative={this.props.relative}
            />
          )}
        </li>
      );
    }

    this.setState({ renderedList: tweetsContainer, initial: false });
  };

  initIntersectionObserver = () => {
    const options = {
      // rootMargin: '500px',
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        const isIntersecting =
          entry.isIntersecting ||
          entry.intersectionRatio > 0 ||
          entry.boundingClientRect.top < 0;
        const isTarget =
          entry.target.id ===
          `c${this.props.columnIndex}-tweet-${this.props.nodeAmount - 1}`;

        if (isTarget && isIntersecting) {
          observer.disconnect();
          this.bottomSentCallback(entry);
          observer.observe(
            document.querySelector(
              `#c${this.props.columnIndex}-tweet-${this.props.nodeAmount - 1}`
            )
          );
        }
      });
    };

    var observer = new IntersectionObserver(callback, options);
    observer.observe(
      document.querySelector(
        `#c${this.props.columnIndex}-tweet-${this.props.nodeAmount - 1}`
      )
    );
    this.setState({ isObserving: true });
  };

  bottomSentCallback = (entry) => {
    if (this.recycling) {
      this.recycling = false;
      return;
    }

    const listLength =
      this.state.list.length || Object.keys(this.state.list).length;
    const currentY = entry.boundingClientRect.top;
    const currentRatio = entry.intersectionRatio;

    if (this.currentIndex >= listLength - this.props.nodeAmount) {
      this.getNext();
    }

    // conditional check for Scrolling down
    if (
      currentY <= this.bottomSentinelPreviousY &&
      currentRatio >= this.bottomSentinelPreviousRatio
    ) {
      const firstIndex = this.getSlidingWindow(true);
      this.saveHeight(firstIndex);
      this.adjustPaddings(this.cachedHeights[firstIndex][0]);
      this.recycleDOM(firstIndex);
      this.currentIndex = firstIndex;
    }

    this.bottomSentinelPreviousY = currentY;
    this.bottomSentinelPreviousRatio = currentRatio;
  };

  getNext() {
    const range = parseInt(
      this.state.initial ? this.props.nodeAmount : this.props.nodeAmount / 2,
      10
    );
    const { list } = this.state;
    const next = this.props.refreshTweetDisplay(
      range,
      this.props.shuffleTweetDisplay
    );
    const newList = [...list, ...next];

    this.setState({ list: newList });
  }

  getSlidingWindow = (isScrollDown) => {
    const increment = this.props.nodeAmount / 2;
    let firstIndex;

    if (isScrollDown) {
      firstIndex = this.currentIndex + increment;
    } else {
      firstIndex = this.currentIndex - increment - this.props.nodeAmount;
    }

    if (firstIndex < 0) {
      firstIndex = 0;
    }

    return firstIndex;
  };

  recycleDOM = (firstIndex) => {
    let tweetsContainer = [];
    const { columnIndex } = this.props;
    this.recycling = true;

    for (let i = 0; i < this.props.nodeAmount; i++) {
      const tweet = this.state.list[i + firstIndex];
      tweetsContainer.push(
        <li
          id={`c${columnIndex}-tweet-${i}`}
          key={tweet.position + '_' + (i + firstIndex)}
          data-source={tweet.source}
        >
          {tweet && (
            <Tweet
              tweet={tweet}
              placeholderImg={this.props.placeholderImg}
              avatarPlaceholder={this.props.avatarPlaceholder}
              relative={this.props.relative}
            />
          )}
        </li>
      );
    }

    this.setState({ renderedList: tweetsContainer });
  };

  getNumFromStyle = (numStr) => Number(numStr.substring(0, numStr.length - 2));

  adjustPaddings = (paddingToAdd) => {
    const container = this.containerRef.current;
    const currentPaddingTop = this.getNumFromStyle(container.style.paddingTop);
    const currentPaddingBottom = this.getNumFromStyle(
      container.style.paddingBottom
    );
    const remPaddingsVal = paddingToAdd;

    container.style.paddingTop = currentPaddingTop + remPaddingsVal + 'px';
    container.style.paddingBottom =
      currentPaddingBottom === 0
        ? '0px'
        : currentPaddingBottom - remPaddingsVal + 'px';
  };

  saveHeight = (firstIndex) => {
    let heightFirstHalf = 0;
    let heightSecondHalf = 0;

    for (let i = 0; i < this.props.nodeAmount; i++) {
      const tile = document.querySelector(
        `#c${this.props.columnIndex}-tweet-${i}`
      );
      if (i < this.props.nodeAmount / 2) {
        heightFirstHalf += tile.offsetHeight;
      } else {
        heightSecondHalf += tile.offsetHeight;
      }
    }
    this.cachedHeights[firstIndex] = [heightFirstHalf, heightSecondHalf];
  };

  render() {
    const styles = style({ count: this.props.columnCount });

    if (!this.state.list.length) return null;

    return (
      <div
        className={`${css(styles.column)} c-${this.props.columnIndex}`}
        ref={this.containerRef}
      >
        {this.state.renderedList.map((cell) => {
          return cell;
        })}
      </div>
    );
  }
}

export default Column;
