import { StyleSheet } from 'aphrodite/no-important';
import { children, media } from '../../util/helpers';

export const style = ({ cmsData, legal, relative, ctaWidthOverride }) =>
  StyleSheet.create({
    logo: {
      marginRight: `-${relative(15)}`,
      flexBasis: ctaWidthOverride ? relative(ctaWidthOverride) : relative(440),
      position: 'relative',
    },

    image: {
      margin: 'auto',
      width: '80%',
      display: 'block',
    },

    cta: {
      padding: '0 10px',
      color: cmsData.copy.color ? cmsData.copy.color : '#fff',
      lineHeight: '1.138',
      fontSize: relative(18),
      fontWeight: 'bold',
      margin: 'auto',
      width: '100%',
      textShadow: '0 2px 34px #0F0231',
      textAlign: 'center',
      ...cmsData.copy,

      [media.tablet]: {
        fontSize: relative(cmsData.copy.fontSize || 25),
      },

      [children('span')]: {
        color: cmsData.hashtag.color ? cmsData.hashtag.color : '#49aaf8',
        display: 'block',
        fontSize: '16px',

        [media.tablet]: {
          fontSize: relative(cmsData.hashtag.fontSize || 25),
        },
      },
    },

    ctaWrapper: {
      position: 'relative',
    },

    ctaBottomImage: {
      height: cmsData.bottom_image_height || 'unset',
      marginTop: '20px',
    },

    legal: {
      color: '#49aaf8',
      marginTop: 25,
      marginBottom: 25,
      position: 'relative',
      fontSize: '24px',
      textAlign: 'center',
      ...legal.copy,

      [media.tablet]: {
        fontSize: relative(legal.copy.fontSize || 25),
      },
    },
  });
