import { StyleSheet } from 'aphrodite/no-important';

export const style = ({ relative }) =>
  StyleSheet.create({
    feed: {
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
    },

    feedWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: `0px ${relative(12)} 0px ${relative(5)}`,
    },

    last: {
      height: '1px',
      backgroundColor: 'transparent',
    },

    defaultTheme: {
      background: 'white',
      icon: 'rgb(61, 181, 255)',
    },
  });
