import { useState, useCallback, useEffect } from 'react';
// TODO: use relative path on hooks library
// import useInterval from './useInterval';
import useInterval from '@telescope/react-hooks/useInterval';

export interface CountdownDisplayUnits {
  days?: boolean;
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
}

export type UnitType = 'days' | 'hours' | 'minutes' | 'seconds';
interface CountdownOptions {
  /**
   * How often the timer should tick. Defaults to 1 second
   */
  interval?: number;
  /**
   * callback function that is called on every tick
   */
  onTick?: (remainingTime: number) => any;
  /**
   * callback function that is called when the countdown reaches 0
   */
  onEnd?: () => any;
  /**
   * Units to be used by the UI
   * Defaults to { days: true, hours: true, minutes: true, seconds: true }
   */
  units?: CountdownDisplayUnits
}

interface ICountdown {
  totalRemaining: number;
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

/**
 * Hook for setting a timer that counts down to a given date.
 * @param endTime Time to countdown to
 * @param options list of options
 * @example
 * // Basic Usage - tick every second
 * const trumpIsGone = new Date('Wed Jan 20 2021 12:00:00 EST');
 * const {months, days, hours, minutes, seconds} = useCountdown(trumpIsGone);
 *
 * // With Options
 * const trumpIsGone = new Date('Wed Jan 20 2021 12:00:00 EST');
 * const onTick = (remaining) => console.log('tick: ', remaining);
 * const onEnd = () => console.log('Trump is gone!!');
 * const {months, days, hours, minutes, seconds} = useCountdown(trumpIsGone, {interval: 5000, onTick, onEnd});
 * const { minutes, seconds } = useCountdown(trumpIsGone, { units: { minutes: true, seconds: true } })
 *
 */

const UNITS_TO_SECONDS = {
  DAY: 86400,
  HOUR: 3600,
  MINUTE: 60,
  SECOND: 1,
}

export default function useCountdown(endTime: Date, options: CountdownOptions = {}) {

  const now = new Date();
  const { interval = 1000, onTick, onEnd, units = { days: true, hours: true, minutes: true, seconds: true } } = options;
  const [ remaining, setRemaining ] = useState<number>(() => getTimeDifference(now, endTime));
  const count = remaining > 0 ? Math.ceil(remaining / interval) : 0;

  const onEndCallback = useCallback(() => {
    if (onEnd) {
      onEnd();
    }
  }, [onEnd]);

  const onTickCallback = useCallback(
    (remainingTime: number) => {
      if (onTick) {
        onTick(remainingTime);
      }
    },
    [onTick]
  );

  const tick = useCallback(() => {
    const now = new Date();
    const timeDiff = getTimeDifference(now, endTime);

    if (timeDiff === 0) {
      onEndCallback();
      setRemaining(timeDiff);
      return;
    }

    onTickCallback(timeDiff);

    setRemaining(timeDiff);
  }, [endTime, onEndCallback, onTickCallback]);

  useEffect(() => {
    const now = new Date();
    setRemaining(getTimeDifference(now, endTime))
  }, [endTime])

  useInterval(tick, count ? interval : null, true);

  const duration = getDuration(now, endTime);

  function getDuration(start: Date, end: Date) {
    const timeDifference = getTimeDifference(start, end);
    const { days = 0, hours = 0, minutes = 0, seconds = 0 } =
      timeDifference === 0 ? {} : convertTimeToCountdown(timeDifference, units);

    return {
      days: formatTimeValue(days),
      hours: formatTimeValue(hours),
      minutes: formatTimeValue(minutes),
      seconds: formatTimeValue(seconds),
    };
  }

  return { ...duration, totalRemaining: remaining };
}

// Helpers
function convertTimeToCountdown(timeDifference: number, units: CountdownDisplayUnits) {
  let timer = { days: '00', hours: '00', minutes: '00', seconds: '00' };
  const { DAY, HOUR, MINUTE, SECOND } = UNITS_TO_SECONDS;
  let remainingTime = timeDifference;

  if (units[TIME_UNITS.DAYS]) {
    timer.days = formatTimeValue(Math.floor(remainingTime / DAY));

    remainingTime %= DAY;
  }

  if (units[TIME_UNITS.HOURS]) {
    timer.hours = formatTimeValue(Math.floor(remainingTime / HOUR));

    remainingTime %= HOUR;
  }

  if (units[TIME_UNITS.MINUTES]) {
    timer.minutes = formatTimeValue(Math.floor(remainingTime / MINUTE));

    remainingTime %= MINUTE;
  }

  if (units[TIME_UNITS.SECONDS]) {
    timer.seconds = formatTimeValue(Math.floor(remainingTime / SECOND));
  }

  return { ...timer };
}

function getTimeDifference(start: Date, end: Date) {
  const diff = (end.getTime() - start.getTime()) / 1000;

  return diff <= 0 ? 0 : diff;
}

function formatTimeValue(val: number | string): string {
  return val.toString().padStart(2, '0');
}

export const TIME_UNITS = {
  DAYS: 'days'  as UnitType,
  HOURS: 'hours'  as UnitType,
  MINUTES: 'minutes'  as UnitType,
  SECONDS: 'seconds'  as UnitType
}
