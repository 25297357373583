import React from 'react';
import ReactImageFallback from 'react-image-fallback';
import { style } from './style';
import { css } from 'aphrodite/no-important';
import { CONTAINS_IMAGE } from '../../util/constants';
import { TwitterIcon, InstagramIcon, FacebookIcon } from '../../util/icons';

const iconMap = {
  twitter: <TwitterIcon />,
  instagram: <InstagramIcon />,
  facebook: <FacebookIcon />,
};

const Tweet = ({ tweet, placeholderImg, avatarPlaceholder, relative }) => {
  const containsImage = tweet.contains.indexOf(CONTAINS_IMAGE) !== -1;
  const theme = tweet.theme;
  const source = tweet.source;
  const styles = style({ theme, containsImage, relative, source });

  return (
    <div className={css(styles.tile)}>
      <div className={css(styles.content)}>
        <div className={css(styles.info)}>
          <div className={css(styles.user)}>
            {tweet.avatar && (
              <ReactImageFallback
                src={tweet.avatar}
                fallbackImage={avatarPlaceholder}
                alt=''
                className={css(styles.avatar)}
              />
            )}
            <div>
              <p className={css(styles.userName)}> {tweet.user_name} </p>
              <p className={css(styles.handle)}>{tweet.displayName}</p>
            </div>
          </div>
          {iconMap[source]}
        </div>

        <p
          className={css(styles.message)}
          dangerouslySetInnerHTML={{ __html: tweet.display_message }}
        />

        {containsImage && (
          <div className={css(styles.image)}>
            <ReactImageFallback
              src={tweet.images}
              fallbackImage={placeholderImg}
              alt=''
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tweet;
