import styled from '@emotion/styled';

import { IGenericObject } from '../models/base';

export const CountdownUnitContainer = styled.div<IGenericObject>(({ customStyles, relative }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: '9',
    ...customStyles.container,

    '& .CountdownUnit__value': {
      padding: '0px',
      lineHeight: 1,
      zIndex: '9',
      fontSize: relative(82),
      letterSpacing: relative(4),
      ...customStyles.value
    },

    '& .CountdownUnit__label': {
      display: 'block',
      fontSize: relative(17),
      zIndex: '9',
      ...customStyles.label
    },
  };
});
