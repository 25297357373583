import React, { Component } from 'react';
import { style } from './style';
import { css } from 'aphrodite/no-important';
// Components
import Feed from '../../components/feed';
import Closed from '../../components/closed';
import BackgroundImage from '../../components/background-image';
// Utils
import { isEmpty, isTrue } from '../../util/helpers';
import { fetchCms } from '../../util/api';
import { APP_WIDTH_LG, APP_WIDTH_SM, POLLING_RATE } from '../../util/constants';

class App extends Component {
  state = {
    cmsData: {},
    isLoaded: false,
    pollingTimer: null,
  };

  async componentDidMount() {
    await this.fetchAppState();
    const pollingTimer = setInterval(() => {
      this.fetchAppState();
    }, POLLING_RATE * 1000);

    this.setState({ isLoaded: true, pollingTimer });
  }

  componentWillUnmount() {
    const { pollingTimer } = this.state;

    clearInterval(pollingTimer);
  }

  fetchAppState = async () => {
    try {
      const cmsData = await fetchCms();

      if (!cmsData) return;

      const oldRefreshToken = isEmpty(this.state.cmsData)
        ? null
        : this.state.cmsData.text.settings.refresh_token;

      if (oldRefreshToken !== null) {
        const newRefreshToken = cmsData.text.settings.refresh_token;

        if (oldRefreshToken !== newRefreshToken) {
          window.location.reload();
          return;
        }
      }

      this.setState({ cmsData });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      cmsData: { text, settings },
      isLoaded,
    } = this.state;

    if (!isLoaded || !text) return null;

    const styles = style({ settings: text.settings, global: text.global });
    const windowOpen = isTrue(settings.window_status);
    const relative = (size) => {
      if (typeof size === 'string') {
        size.replace('px', '');
        size = parseInt(size, 10);
      }

      if (text.settings.width_override) return `${size}px`;
      const width =
        text.grid.settings.column_count > 4 ? APP_WIDTH_LG : APP_WIDTH_SM;
      return `${(size * 100) / width}vw`;
    };

    return (
      <section className={css(styles.app)}>
        <BackgroundImage
          settings={text.settings}
          background={text.app.background}
        />

        {!windowOpen && (
          <Closed
            cmsData={text.closed}
            columnCount={text.grid.settings.column_count}
            relative={relative}
          />
        )}

        {windowOpen && (
          <Feed
            topicId={text.settings.topic_id}
            themes={text.grid.text_themes}
            imageTheme={text.grid.image_theme}
            scrollSpeed={text.settings.scrolling_speed}
            minTweetsAmount={text.grid.settings.minimum_tweets_amount}
            columnCount={text.grid.settings.column_count}
            selfScroll={isTrue(text.settings.display_self_scrolling)}
            placeholderImg={text.grid.settings.placeholder_image}
            avatarPlaceholder={text.grid.settings.avatar_placeholder_image}
            nodeAmount={text.grid.settings.node_amount}
            shuffleTweetDisplay={text.grid.settings.shuffleTweetDisplay}
            relative={relative}
            tweetsPollingRate={text.settings.tweets_polling_rate}
            cmsData={text.grid.cta}
            legal={text.grid.legal}
            settings={text.settings}
            background={text.app.cta_background}
            displayLogo={text.grid.settings.display_logo}
            countdown={text.countdown}
          />
        )}
        {!!text.global.font.font_import && (
          <link href={text.global.font.font_import} rel='stylesheet' />
        )}
      </section>
    );
  }
}

export default App;
