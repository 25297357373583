import React from 'react';
import { style } from './style';
import { css } from 'aphrodite/no-important';

const Closed = ({ cmsData, columnCount, relative }) => {
  const { cta } = cmsData;
  const styles = style({ cta, relative });
  const copy = cta.copy.value.replace(
    '{{HASHTAG}}',
    `<span>${cta.hashtag.value}</span>`
  );

  return (
    <section className={css(styles.closed)}>
      <div className={css(styles.ctaWrapper)}>
        <img className={css(styles.image)} src={cta.image} alt='' />
        <p
          className={css(styles.cta)}
          dangerouslySetInnerHTML={{ __html: `${copy}` }}
        />
      </div>
    </section>
  );
};

export default Closed;
