import React from 'react';
import { style } from './style';
import { css } from 'aphrodite/no-important';
import BackgroundImage from '../../components/background-image';
import { getInt, isTrue } from '../../util/helpers';
import { Countdown } from '../countdown-group/Countdown/Countdown';

const Logo = ({
  cmsData,
  countdown,
  legal,
  columnCount,
  settings,
  background,
  relative,
}) => {
  const widthOverride = getInt(settings.width_override);
  const ctaWidthOverride = getInt(settings.cta_width_override);
  const hardcodedHeight = getInt(settings.height_override);
  const styles = style({
    cmsData,
    legal,
    columnCount,
    widthOverride,
    ctaWidthOverride,
    hardcodedHeight,
    relative,
  });
  const copy = cmsData.copy.value.replace(
    '{{HASHTAG}}',
    `<span>${cmsData.hashtag.value}</span>`
  );
  const endTime = new Date(countdown.settings.countdown_to_date);

  return (
    <div className={css(styles.logo)}>
      <BackgroundImage settings={settings} background={background} />

      <div className={css(styles.ctaWrapper)}>
        <img src={cmsData.image} alt='' />
        <p
          className={css(styles.cta)}
          dangerouslySetInnerHTML={{ __html: `${copy}` }}
        />
      </div>
      {legal.copy.value && (
        <p className={css(styles.legal)}>{legal.copy.value}</p>
      )}
      <Countdown
        to={endTime}
        displayUnits={{
          days: isTrue(countdown.settings.display_days),
          hours: isTrue(countdown.settings.display_hours),
          minutes: isTrue(countdown.settings.display_minutes),
          seconds: isTrue(countdown.settings.display_seconds),
        }}
        labels={countdown.labels.copy.unit_labels}
        separator={countdown.separator.copy.separator_content}
        customStyles={{
          labels: countdown.labels.styles,
          unit: countdown.countdown_unit.styles,
          container: countdown.container.styles,
          separator: countdown.separator.styles,
        }}
        relative={relative}
      />
      {!!cmsData?.bottom_image && (
        <div className={css(styles.ctaWrapper)}>
          <img
            src={cmsData.bottom_image}
            alt=''
            className={css(styles.ctaBottomImage)}
          />
        </div>
      )}
    </div>
  );
};

export default Logo;
