// App Settings
export const DEFAULT_WID = 'd1e030a6a704b34a';
export const POLLING_RATE = 10;

// Endpoints
export const API_CMS = 'https://widgetstate.votenow.tv/v1/state/';
export const API_MODERATION =
  'https://voteapi.votenow.tv/moderation/getMessages';

// Tweets
export const CONTAINS_IMAGE = 'contains_image';
export const MIN_TWEET_AMOUNT = 50;

export const COLUMN_COUNT_DEFAULT = 5;
export const COLUMN_COUNT_SQUARE = 3;
export const APP_WIDTH_LG = 1920;
export const APP_WIDTH_SM = 1536;
