import axios from 'axios';
import axiosETAGCache from 'axios-etag-cache';
import queryString from 'query-string';

import { API_CMS, DEFAULT_WID, API_MODERATION } from './constants';

export async function fetchCms() {
  const params = queryString.parse(window.location.search);

  let apiPath = params.wid || DEFAULT_WID;
  apiPath += params.sid ? '/snapshot/' + params.sid : '';

  return axiosETAGCache(axios)
    .get(API_CMS + apiPath)
    .then((res) => {
      if (!('etag' in res.headers)) {
        return null;
      }
      return res.data.snapshot.data;
    })
    .catch((err) => {
      return null;
    });
}

export async function fetchTweets(topicId, positionId, offset) {
  const params = {
    topic_id: topicId,
    position: positionId,
    offset: offset,
    format: 'list',
  };

  const url = `${API_MODERATION}?${queryString.stringify(params)}`;
  const { data } = await axios.get(url);

  const normalizedData = normalizeMessages(Object.values(data));

  return normalizedData;
}

/**
 * normalizeMessages
 * The messages returned from Moderation API are inconsistent from one source to another.
 * This reshapes the data to be consumed by the app in a consistent format.
 * @param {array} array of messages to normalize
 */
function normalizeMessages(data) {
  const dataArray = data.map((message) => {
    const source = message.source.toLowerCase();
    const newMessage = { ...message };

    switch (source) {
      case 'flex':
        newMessage.displayName = message.screen_name;
        break;
      case 'facebook':
        newMessage.displayName = message.user_name;
        break;
      case 'instagram':
        newMessage.displayName = `@${message.screen_name}`;
        break;
      case 'twitter':
        newMessage.displayName = `@${message.screen_name}`;
        break;
      default:
        newMessage.displayName = message.screen_name;
    }

    newMessage.source = source;

    return newMessage;
  });

  return dataArray;
}
