import { StyleSheet } from 'aphrodite/no-important';
import { children } from '../../util/helpers';

export const style = ({ theme, containsImage, relative, source }) =>
  StyleSheet.create({
    tile: {
      padding: `0 ${relative(12)} ${relative(25)}`,
      width: '100%',
      display: 'inline-block',
      transform: 'translate3d(0, 0, 0)',
      willChange: 'transform',
    },

    content: {
      backgroundColor: theme.background,
      border: `${relative(11)} solid ${theme.background}`,
      height: '100%',
      minHeight: relative(180),
    },

    info: {
      color: theme.copy,
      display: 'flex',
      justifyContent: source === 'flex' ? 'flex-start' : 'space-between',
      alignItems: 'center',
      fontSize: relative(19.5),
      fontWeight: '700',
      padding: `0`,
      gap: 10,

      [children('svg')]: {
        height: relative(24),
        width: relative(24),
        fill: theme.icon,
      },
    },

    avatar: {
      width: relative(30),
      height: relative(30),
      marginRight: relative(8),
      borderRadius: '50%',
    },

    message: {
      color: theme.copy,
      fontSize: relative(16),
      lineHeight: '1.4',
      padding: `${relative(20)} 0`,
      fontWeight: '400',
      overflowWrap: 'break-word',

      [children('.is_keyword')]: {
        color: theme.hashtag,
      },

      [children('.is_link')]: {
        pointerEvents: 'none',
        display: 'none',
      },

      a: {
        display: 'none',
      },
    },

    user: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 2,

      [children('div')]: {
        textAlign: 'left',
        overflow: 'hidden',
      },
    },

    userName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '180px',
      whiteSpace: 'nowrap',
      color: theme.name,
      fontSize: relative(13),
      lineHeight: 1.3,
    },

    image: {
      padding: '0',
    },

    handle: {
      color: theme.name,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: relative(13),
      fontWeight: 400,
      lineHeight: 1.3,
    },
  });
