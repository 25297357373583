export const breakpoints = {
  xs: 320,
  mobile: 375,
  tablet: 768,
  desktop: 980,
  xl: 1216,
};

export function isEmpty(obj) {
  return !Object.keys(obj).length;
}

export const isTrue = (value) => {
  return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export const children = (child) => {
  return `:nth-child(1n) ${child}`;
};

// Breakpoints
export const media = {
  xs: `@media (min-width: ${breakpoints.xs}px)`,
  mobile: `@media (min-width: ${breakpoints.mobile}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`,
  xl: `@media (min-width: ${breakpoints.xl}px)`
};

export function shuffle(arr) {
  var rand, temp, i;

  for (i = arr.length - 1; i > 0; i -= 1) {
    rand = Math.floor((i + 1) * Math.random());
    temp = arr[rand];
    arr[rand] = arr[i];
    arr[i] = temp;
  }
  return arr;
}

export function getInt(value) {
  if (!value) return;

  return value.replace(/\D/g, '');
}
