import { StyleSheet } from 'aphrodite/no-important';
import { children } from '../../util/helpers';

export const style = ({ cta, relative }) =>
  StyleSheet.create({
    closed: {
      padding: relative(200),
      width: '100%',
      height: '100vh',
    },

    image: {
      margin: '0 auto 20px',
      width: 'auto',
      maxWidth: '50%',
      display: 'block',
    },

    cta: {
      color: cta.copy.color ? cta.copy.color : '#fff',
      fontSize: cta.copy.fontSize ? cta.copy.fontSize : relative(70),
      fontWeight: '700',
      lineHeight: '1.138',
      margin: '0 auto',
      width: '100%',
      textAlign: 'center',
      fontFamily: 'nimbus-sans-extended',

      [children('span')]: {
        color: cta.hashtag.color ? cta.hashtag.color : '#F9F204',
        display: 'block',
        fontSize: cta.hashtag.fontSize ? cta.hashtag.fontSize : relative(80),
      },
    },

    ctaWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      height: '100%',
    },
  });
