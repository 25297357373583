import { StyleSheet } from 'aphrodite/no-important';

export const style = (params) =>
  StyleSheet.create({
    app: {
      position: 'relative',
      width: params.settings.width_override || '100vw',
      height: params.settings.height_override || '100vh',
      overflow: 'hidden',
      fontFamily: params.global.font.font_family
        ? params.global.font.font_family
        : 'inherit',
    },
  });
