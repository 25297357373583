import React from 'react';

export const TwitterIcon = () => {
  return (
    <span className='twitter-icon'>
      <svg viewBox='0 -100 1200 1400'>
        <path d='M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z' />
      </svg>
    </span>
  );
};

export const InstagramIcon = () => {
  return (
    <span className='instagram-icon'>
      <svg width='512' height='512' viewBox='0 0 512 512'>
        <path d='M256 49.47c67.27 0 75.23.26 101.8 1.47 24.56 1.12 37.9 5.22 46.78 8.67 11.76 4.57 20.15 10.03 28.97 18.84 8.82 8.82 14.28 17.21 18.84 28.97 3.45 8.88 7.55 22.22 8.67 46.78 1.21 26.56 1.47 34.53 1.47 101.8s-.26 75.23-1.47 101.8c-1.12 24.56-5.22 37.9-8.67 46.78-4.57 11.76-10.03 20.15-18.84 28.97-8.82 8.82-17.21 14.28-28.97 18.84-8.88 3.45-22.22 7.55-46.78 8.67-26.56 1.21-34.53 1.47-101.8 1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67-11.76-4.57-20.15-10.03-28.97-18.84-8.82-8.82-14.28-17.21-18.84-28.97-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.56-1.47-34.53-1.47-101.8s.26-75.23 1.47-101.8c1.12-24.56 5.22-37.9 8.67-46.78 4.57-11.76 10.03-20.15 18.84-28.97 8.82-8.82 17.21-14.28 28.97-18.84 8.88-3.45 22.22-7.55 46.78-8.67 26.57-1.21 34.53-1.47 101.8-1.47m0-45.39c-68.42 0-77 .29-103.87 1.52-26.81 1.22-45.13 5.48-61.15 11.71-16.57 6.44-30.62 15.05-44.62 29.06-14 14-22.62 28.05-29.06 44.61C11.07 107 6.81 125.32 5.59 152.13 4.37 179 4.08 187.58 4.08 256s.29 77 1.52 103.87c1.22 26.81 5.48 45.13 11.71 61.15 6.44 16.57 15.05 30.62 29.06 44.62 14.01 14.01 28.05 22.62 44.62 29.06 16.02 6.23 34.34 10.49 61.15 11.71 26.87 1.23 35.45 1.52 103.87 1.52s77-.29 103.87-1.52c26.81-1.22 45.13-5.48 61.15-11.71 16.57-6.44 30.62-15.05 44.62-29.06 14.01-14.01 22.62-28.05 29.06-44.62 6.23-16.02 10.49-34.34 11.71-61.15 1.23-26.87 1.52-35.45 1.52-103.87s-.29-77-1.52-103.87c-1.22-26.81-5.48-45.13-11.71-61.15-6.44-16.57-15.05-30.62-29.06-44.62-14.01-14.01-28.05-22.62-44.62-29.06-16.02-6.23-34.34-10.49-61.15-11.71C333 4.37 324.42 4.08 256 4.08z' />
        <path d='M256 126.64c-71.45 0-129.36 57.92-129.36 129.36S184.55 385.36 256 385.36 385.36 327.45 385.36 256 327.45 126.64 256 126.64zm0 213.33c-46.38 0-83.97-37.6-83.97-83.97s37.6-83.97 83.97-83.97c46.38 0 83.97 37.6 83.97 83.97s-37.59 83.97-83.97 83.97z' />
        <circle cx='390.48' cy='121.52' r='30.23' />
      </svg>
    </span>
  );
};

export const FacebookIcon = () => {
  return (
    <span className='facebook-icon'>
      <svg width='512' height='512' viewBox='0 0 1024 1024'>
        <path
          d='M512,0C229.23,0,0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512h130V399.2C432,270.88,508.44,200,625.39,200
	C681.41,200,740,210,740,210v126h-64.56c-63.6,0-83.44,39.47-83.44,79.96V512h142l-22.7,148H592v357.78
	c244.77-38.41,432-250.22,432-505.78C1024,229.23,794.77,0,512,0z'
        />
      </svg>
    </span>
  );
};
