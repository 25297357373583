import { StyleSheet } from 'aphrodite/no-important';

export const style = (params) =>
  StyleSheet.create({
    customBackgroundImage: {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      background: params.background.color || [
        'rgb(57,130,181)',
        'linear-gradient(180deg, rgba(57,130,181,1) 60%, rgba(0,0,0,1) 100%)',
      ],
      backgroundImage: `url(${params.background.image})` || '',
      height: '100vh',
      backgroundSize: 'cover',
    },

    overlay: {
      position: 'fixed',
      left: '0',
      top: '0',
      backgroundColor: 'rgba(15, 2, 49, 0.38)',
      width: '100%',
      height: '100%',
    },
  });
