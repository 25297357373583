export function scrollElement(element, scrollPosition, duration) {
  var style = element.style;

  // setup CSS transition duration and easing function
  style.webkitTransition = style.transition = duration + 's';
  style.webkitTransitionTimingFunction = style.TransitionTimingFunction =
    'linear';
  style.webkitTransitionProperty = style.transitionProperty = 'transform';

  // use translate3d to force hardware acceleration
  style.webkitTransform = style.Transform =
    'translate3d(0, ' + -scrollPosition + 'px, 0)';
}
