import React, { Component } from 'react';
import { style } from './style';
import { css } from 'aphrodite/no-important';
import { fetchTweets } from '../../util/api';
import { shuffle } from '../../util/helpers';
import { POLLING_RATE } from '../../util/constants';
import { scrollElement } from '../../util/scroll';
import Column from '../../components/column';
import Logo from '../logo';
import { isTrue, isEmpty } from '../../util/helpers';

class Feed extends Component {
  constructor() {
    super();

    // A buffer of all of our tweets, up to 500 total.
    this.tweetsBuffer = [];
    // The Moderation position of last known newest tweet
    this.newestBufferPosition = 0;

    // Handles for the timeout calls.
    this.apiTimeout = 0;
    this.renderTimeout = 0;

    this.handleScroll = this.onScroll.bind(this);
    this.refreshTweetDisplay = this.refreshTweetDisplay.bind(this);

    // Next scrolling position
    this.pageOffset = 0;

    this.containerRef = React.createRef();
  }

  state = {
    isReady: false,
    isScrolling: false,
  };

  componentWillUnmount() {
    clearTimeout(this.apiTimeout);
    clearTimeout(this.renderTimeout);
    this.containerRef.current.removeEventListener(
      'webkitTransitionEnd',
      this.handleScroll
    );
  }

  async componentDidMount() {
    await this.refreshTweetFeed();

    this.startScroll();
    this.containerRef.current.addEventListener(
      'webkitTransitionEnd',
      this.handleScroll,
      false
    );
  }

  componentDidUpdate() {
    this.startScroll();
  }

  refreshTweetFeed = async () => {
    try {
      const { topicId } = this.props;
      const positionId = this.tweetsBuffer.length
        ? this.newestBufferPosition
        : undefined;
      const offset = positionId ? 500 : undefined;
      const newTweets = await fetchTweets(topicId, positionId, offset);

      newTweets.forEach((tweet, i) => {
        tweet.theme = this.getTweetTheme(tweet);
      });

      if (this.tweetsBuffer.length) {
        this.tweetsBuffer = [...newTweets, ...this.tweetsBuffer].slice(0, 500);
      } else {
        this.tweetsBuffer = newTweets;
      }

      if (newTweets.length) {
        this.newestBufferPosition = newTweets[0].position;
      }

      if (!this.state.isReady && this.tweetsBuffer.length) {
        this.setState({ isReady: true });
      }
      this.startScroll();
    } catch (err) {
      console.log(err);
    }
    const pollingRate = (this.props.tweetsPollingRate || POLLING_RATE) * 10000;
    this.apiTimeout = setTimeout(this.refreshTweetFeed, pollingRate);
  };

  getTweetTheme = (tweet) => {
    const themes = Object.values(this.props.themes);
    const { imageTheme } = this.props;
    const colorIndex = Math.floor(Math.random() * themes.length);
    const containsImage = tweet.images !== '';
    const theme = { ...themes[colorIndex] };
    const styles = style({ relative: this.props.relative });

    if (isEmpty(theme)) {
      theme.background = styles.defaultTheme._definition.background;
      theme.icon = styles.defaultTheme._definition.icon;
    } else {
      theme.background =
        theme.background || styles.defaultTheme._definition.background;
      theme.icon = theme.icon || styles.defaultTheme._definition.icon;
    }

    if (containsImage) {
      if (!!imageTheme.background) {
        theme.background = imageTheme.background;
      }
      if (!!imageTheme.icon) {
        theme.icon = imageTheme.icon;
      }
      if (!!imageTheme.name) {
        theme.name = imageTheme.name;
      }
    }
    return theme;
  };

  refreshTweetDisplay = (range, shuffleTweets = true) => {
    const nextTweetsToDisplay = this.tweetsBuffer.splice(0, range);

    this.tweetsBuffer.push(...nextTweetsToDisplay);

    return shuffleTweets ? shuffle(nextTweetsToDisplay) : nextTweetsToDisplay;
  };

  startScroll() {
    const { isScrolling, isReady } = this.state;
    const { selfScroll } = this.props;

    if (isReady && !isScrolling & selfScroll) {
      this.setState({ isScrolling: true });
      setTimeout(() => {
        this.scrollPage();
      }, 100);
    }
  }

  scrollPage() {
    if (!this.props.selfScroll) return;
    this.pageOffset = this.pageOffset + 100;
    scrollElement(
      this.containerRef.current,
      this.pageOffset,
      this.props.scrollSpeed
    );
  }

  onScroll(e) {
    this.scrollPage();
  }

  buildColumns() {
    const {
      columnCount,
      nodeAmount,
      placeholderImg,
      relative,
      avatarPlaceholder,
      shuffleTweetDisplay,
    } = this.props;
    const count = parseInt(columnCount) || 5;
    let columns = [];

    for (let i = 0; i < count; i++) {
      columns.push(
        <Column
          refreshTweetDisplay={this.refreshTweetDisplay}
          columnCount={count}
          columnIndex={i}
          key={i}
          placeholderImg={placeholderImg}
          avatarPlaceholder={avatarPlaceholder}
          nodeAmount={parseInt(nodeAmount, 10)}
          shuffleTweetDisplay={shuffleTweetDisplay}
          relative={relative}
        />
      );
    }

    return columns;
  }

  render() {
    const styles = style({ relative: this.props.relative });
    const {
      displayLogo,
      cmsData,
      legal,
      columCount,
      settings,
      background,
      relative,
      countdown,
    } = this.props;
    const cta_settings = {
      ...settings,
      width_override: settings?.cta_width_override,
    };

    if (!this.state.isReady) {
      return null;
    }

    return (
      <section className={css(styles.feed)}>
        {isTrue(displayLogo) && (
          <Logo
            cmsData={cmsData}
            legal={legal}
            columnCount={columCount}
            settings={cta_settings}
            background={background}
            relative={relative}
            countdown={countdown}
          />
        )}
        <section className={css(styles.feedWrapper)} ref={this.containerRef}>
          {this.buildColumns()}
        </section>
      </section>
    );
  }
}

export default Feed;
