import React from 'react';
import { style } from './style';
import { css } from 'aphrodite/no-important';

const BackgroundImage = ({ settings, background }) => {
  const styles = style({ settings, background });
  const showOverlay = background?.has_overlay !== 'false';

  return (
    <>
      <div className={css(styles.customBackgroundImage)}></div>
      {showOverlay && <div className={css(styles.overlay)} />}
    </>
  );
};

export default BackgroundImage;
