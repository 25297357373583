import React, { FC } from 'react';

import { CountdownUnitContainer } from './CountdownUnit.styles';
import { CountdownUnitProps } from './CountdownUnit.types';

export const CountdownUnit: FC<CountdownUnitProps> = ({ value, label, displayLabel = true, ariaHidden = false, customStyles = {}, relative }) => {
  const unitLabel = value === '01' ? label.singular : label.plural;

  return (
    <CountdownUnitContainer aria-hidden={ariaHidden} customStyles={{ ...customStyles }} relative={relative}>
      <span className='CountdownUnit__value'> {value} </span>

      {displayLabel && <span className='CountdownUnit__label'> {unitLabel} </span>}
    </CountdownUnitContainer>
  );
};
