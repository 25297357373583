import styled from '@emotion/styled';

import { IGenericObject } from '../models/base';

export const CountdownContainer = styled.div<IGenericObject>((props) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '9',
    maxWidth: props.relative(250),
    marginLeft: 'auto',
    marginRight: 'auto',
    ...props.customStyles,
  };
});

export const Separator = styled.span<IGenericObject>((props) => {
  return {
    margin: '0 1.5vmin',
    zIndex: '9',
    lineHeight: 1,
    transform: 'translateY(-16%)',
    ...props.customStyles,
    fontSize: props.relative(75),
  };
});
