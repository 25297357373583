import useCountdown, { UnitType } from '../hooks/useCountdown';
import React, { FC } from 'react';

import { CountdownUnit } from '../CountdownUnit/CountdownUnit';
import { CountdownContainer, Separator } from './Countdown.styles';
import { CountdownProps } from './Countdown.types';
import { IGenericObject } from '../models/base';



export const Countdown: FC<CountdownProps> = ({
  to,
  displayUnits = {},
  displayLabels = true,
  labels = {},
  separator = ':',
  customStyles = {},
  relative
}) => {
  const duration = useCountdown(to, { units: displayUnits });
  const enabled = Object.keys(displayUnits).filter((unit) => {
    // Test the variable locally to prevent Typescript undefined error
    // https://stackoverflow.com/questions/49610779/typescript-error-ts2532-object-is-possibly-undefined-even-after-undefined-c
    const unitsObj: IGenericObject = displayUnits;
    return unitsObj[unit]
  });

  const renderUnits = () => {
    return enabled.map((unit, index) => {
        return (
          <React.Fragment key={index}>
            {!!index && (
              <Separator
                dangerouslySetInnerHTML={{ __html: separator }}
                customStyles={customStyles.separator}
                relative={relative} />
            )}
            <CountdownUnit
              value={duration[unit as UnitType]}
              displayLabel={displayLabels}
              label={labels[unit]}
              customStyles={{ container: customStyles.unit, label: customStyles.labels, value: customStyles.unit }} // Missing customStyles.value
              relative={relative}
            />
          </React.Fragment>
        );
      });
  };

  return (
    <CountdownContainer aria-live='assertive' aria-atomic='true' customStyles={customStyles.container} relative={relative}>
      { renderUnits() }
    </CountdownContainer>
  );
};
